import React from 'react';

import { Image, Container, Card, Embed, Segment, Grid} from 'semantic-ui-react';
import Image1 from '../../../assets/About/Image1.jpg'
import Image2 from '../../../assets/About/Image2.png'
import Image3 from '../../../assets/About/Image3.png'
import Image4 from '../../../assets/About/Image4.png'
import Image5 from '../../../assets/About/Image5.png'
import Image6 from '../../../assets/About/Image6.png'
import Image7 from '../../../assets/About/Image7.png'

import ImagePlcHldr from '../../../assets/About/placeholder.jpg'


const AboutScales: React.FC = () => {

    return (
        <Container>
            <Segment inverted></Segment>
            
            <Card fluid style={{ background: 'black' }}>

                <Image src={Image1}></Image>
            </Card>

            <Segment color='black' inverted>
                <Grid columns={2} stackable textAlign='center'>
                    <Grid.Row verticalAlign='middle'>
                        <Grid.Column>
                            <Card fluid>
                                <Card.Content>
                                    <Embed autoplay={true}
                                        source='youtube'
                                        id='1vQsLKxZuig'
                                        placeholder={ImagePlcHldr} />
                                </Card.Content>
                            </Card>
                        </Grid.Column>
                        <Grid.Column>
                            <p style={{ textAlign: 'justify' }}>
                                Пасечные весы для пчеловодов BeeHoney.by - то, что
                                обязательно должно быть на любой пасеке, для понимания, что происходит с их пчелками.
                                Весы могут работать по сети GSM(Нужна сим-карта), WiFi, а так же через SMS(нужна сим-карта).
                            </p>
                            <p style={{ textAlign: 'justify' }}>
                                Данные отправляются на сайт( в личный кабинет, в котором можно смотреть вес, температуру, влажность, давление, заряд батареи,
                                мощность сигнала), а при работе через SMS - непосредственно на телефон владельца.
                                Вы всегда понимаете, есть ли в природе взяток. Если выйдет рой, весы оповестят Вас звонком, либо, если не дозвонятся - отправят SMS.
                            </p>
                            <p style={{ textAlign: 'justify' }}>
                                Так же присутствует GSM сигнализация. При открывании крышки улья незамедлительно
                                происходит оповщение владельца об этом инциденте.
                            </p>
                            <p style={{ textAlign: 'justify' }}>
                                В комплекте поставляется солнечная батарея,
                                которая позволяет не заряжать аккумуляторы весь сезон.
                            </p>
                        </Grid.Column>

                    </Grid.Row>
                </Grid>
            </Segment>

            <Card.Group
                itemsPerRow={3}
                stackable
            >

                <Card style={{ background: 'black' }} >
                    <Card.Content>
                        <Image src={Image2} />
                        <Card.Header style={{ color: 'white' }} >Металлическая платформа.</Card.Header>
                        <Card.Description style={{ color: 'white' }}>Использование прочной металлической платформы позволяет быстро установить весы под улей.</Card.Description>

                    </Card.Content>
                </Card>

                <Card style={{ background: 'black' }}>
                    <Card.Content>
                        <Image src={Image3} />
                        <Card.Header style={{ color: 'white' }}>Блок аккумуляторов и зарядки.</Card.Header>
                        <Card.Description style={{ color: 'white' }}>В одном корпусе находятся и аккумуляторы и блок зарядки, что позволяет либо поменять аккумуляторы на месте, либо их зарядить дома или в автомобиле.</Card.Description>

                    </Card.Content>
                </Card>

                <Card style={{ background: 'black' }}>
                    <Card.Content>
                        <Image src={Image4} />
                        <Card.Header style={{ color: 'white' }}>Блок управления.</Card.Header>
                        <Card.Description style={{ color: 'white' }}>В блоке управления находится вся электроника. В нем также находится и датчики давления и температуры системы.</Card.Description>

                    </Card.Content>
                </Card>

                <Card style={{ background: 'black' }}>
                    <Card.Content>
                        <Image src={Image5} />
                        <Card.Header style={{ color: 'white' }}>Датчик влажности.</Card.Header>
                        <Card.Description style={{ color: 'white' }}>Датчик влажности позволяет понять был ли дождь на пасеке.</Card.Description>

                    </Card.Content>
                </Card>

                <Card style={{ background: 'black' }}>
                    <Card.Content>
                        <Image src={Image6} />
                        <Card.Header style={{ color: 'white' }}>Датчик веса.</Card.Header>
                        <Card.Description style={{ color: 'white' }}>В весах используется 4 датчика веса в мостовой схеме включения. Общая допустимая нагрузка равна 200 кг.</Card.Description>

                    </Card.Content>
                </Card>

                <Card style={{ background: 'black' }}>
                    <Card.Content>
                        <Image src={Image7} />
                        <Card.Header style={{ color: 'white' }}>Датчик температуры.</Card.Header>
                        <Card.Description style={{ color: 'white' }}>Позволяет понимать какая температура в данный момент на пасеке и анализировать влияние температуры на работу ваших пчёл.</Card.Description>

                    </Card.Content>
                </Card>

            </Card.Group>

            <Segment inverted></Segment>

        </Container>

    );
}

export default AboutScales;