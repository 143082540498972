import React from 'react';

import { Container, Card, Segment } from 'semantic-ui-react';
import { Link } from 'react-router-dom';



const About: React.FC = () => {

    return (
        <Container>

            <Card.Group
                itemsPerRow={2}
                stackable
            >

                <Card style={{ background: 'black' }} href="/about/scales">
                    <Card.Content>

                        <Card.Header style={{ color: "white" }}>
                            Описание весов.
                        </Card.Header>
                        <Card.Description style={{ color: "white" }}>
                            <p>Содержит информацию о возможностях и характеристиках пасечных весов.</p>
                        </Card.Description>

                    </Card.Content>
                </Card>


                <Card style={{ background: 'black' }} href="/about/site">
                    <Card.Content>

                        <Card.Header style={{ color: "white" }}>Описание работы с весами через личный кабинет.</Card.Header>
                        <Card.Description style={{ color: "white" }}>
                            <p>Содержит информацию о способах настройки весов через личный кабинет на сайте, а так же о возможностях отображения информации.</p>
                        </Card.Description>

                    </Card.Content>
                </Card>

            </Card.Group>

        </Container>

    );
}

export default About;