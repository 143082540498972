import React from 'react';

import { Image, Container, Card, Segment } from 'semantic-ui-react';

import Image8 from '../../../assets/About/Image8.png'
import Image9 from '../../../assets/About/Image9.png'
import Image10 from '../../../assets/About/Image10.png'
import Image11 from '../../../assets/About/Image11.png'
import Image12 from '../../../assets/About/Image12.png'
import Image13 from '../../../assets/About/Image13.png'
import Image14 from '../../../assets/About/Image14.png'
import Image15 from '../../../assets/About/Image15.png'
import Image16 from '../../../assets/About/Image16.png'
import Image17 from '../../../assets/About/Image17.png'
import Image18 from '../../../assets/About/Image18.png'
import ImgSettings from '../../../assets/About/ImgSettingsBtn.png'


const AboutSite: React.FC = () => {

    return (
        <Container>
            <Segment inverted></Segment>
            <Card.Group
                itemsPerRow={3}
                stackable
            >

                <Card style={{ background: 'black' }} >
                    <Card.Content>
                        <Card.Description style={{ color: 'white' }}>В личном кабинете пользователя при нажатии кнопки с именем пользователя выскакивает подменю, в котором можно выбрать два пункта, либо выйти из кабинета пользователя, либо перейти в профиль(режим настроек):</Card.Description>
                        <Image src={Image8} size='medium' />

                    </Card.Content>
                </Card>

                <Card style={{ background: 'black' }}>
                    <Card.Content>
                        <Card.Description style={{ color: 'white' }}>После выбора Меню Профиль мы попадаем на следующую страницу:</Card.Description>
                        <Image src={Image9} size='medium' />
                        <Card.Description style={{ color: 'white' }}>На данном этапе пользователь может сменить пароль от личного кабинета, подключить личный кабинет к Вашему Telegram аккаунту на телефоне, после подключения данной функции, оповещения, которые приходят от весов будут приходить в Ваш аккаунт Telegram.</Card.Description>
                        <Card.Description style={{ color: 'white' }}>Так же мы видим здесь Ваши весы (их название). Здесь можно зайти в настройки Ваших весов, нажав кнопочку <Image src={ImgSettings} size='mini' /> - "редактировать"</Card.Description>

                    </Card.Content>
                </Card>

                <Card style={{ background: 'black' }}>
                    <Card.Content>
                        <Image src={Image10} size='medium' />

                        <Card.Description style={{ color: 'white' }}>Сверху в окне пользователь может видеть номер своих весов, версию прошивки весов(fw) и версию прошивки файловой системы(fs).
                            На данном этапе, пользователь может изменять названия улья, под которым стоят весы, относить весы, если у Вас они не одни к разным пасекам? Изменять цвет графика, период отправки данных (задается в минутах), выбирать режим отправки. Доступно 3 режима: 1. GSM (отправка данных с помощью мобильного оператора (необходима сим-карта) в личный кабинет пользователя; 2. Wi-Fi (отправка данных через беспроводное соединение) в личный кабинет пользователя; 3. SMS (отправка с помощью мобильного оператора (необходима сим-карта) SMS сообщения на телефон, указанный в настройках весов.
                        </Card.Description>

                    </Card.Content>
                </Card>

                <Card style={{ background: 'black' }}>
                    <Card.Content>
                        <Image src={Image11} />

                        <Card.Description style={{ color: 'white' }}>Можно выставлять число попыток отправки данных. Необходимо понимать, что весы стоят в самом низу, практически у земли и обычно сигнал оператора сотовой связи там всегда меньше, чем у Вас на мобильном телефоне, поэтому отправить данные весы могут не с первого раза, рекомендуемое значение от 3 до 5. Если и за это время весы не отправляют данные, то необходимо переместить весы в другое место, так как сигнала мобильного оператора недостаточно для отправки данных через GSM.
                            Уведомление о разряде батареи позволяет пользователю заранее узнать, что на весах садится аккумулятор. Оповещение придет в виде SMS, если весы работают по GSM, либо в Telegram, если весы работаю по Wi-Fi. Внимание, с весами в комплекте идет солнечная батарея, которая практически позволяет не обращать внимания на заряд аккумуляторов весь сезон.
                            Контроль выхода роя позволяет Вам контролировать резкое изменение веса на Ваших весах. Очень полезная функция. Оповещения приходят точно так же, как и при разряде батареи, описанном выше.
                            Важно!!! Все оповещения приходят с периодичностью периода отправки данных, указанных выше, кроме GSM сигнализации.
                            Если включена функция GSM сигнализации, при открывании крышки улья, весы незамедлительно оповещают владельца весов об этом. Датчик открытия крышки улья идет в комплекте с весами.
                        </Card.Description>

                    </Card.Content>
                </Card>

                <Card style={{ background: 'black' }}>
                    <Card.Content>
                        <Card.Description style={{ color: 'white' }}>Если нажать на Опции связи перед Вами откроется следующее меню.</Card.Description>
                        <Image src={Image12} />

                        <Card.Description style={{ color: 'white' }}>Здесь пользователь может задать свой номер телефона, изменить название весов, для подключения к ним по Wi-Fi, изменить пароль доступа к весам.
                            Так же если пользователь выбирает режим работы весов по Wi-Fi, здесь пользователь вводит точное название его Wi-Fi сети, к которой весы будут каждый раз подключаться для отправки данных и пароль от этой Wi-Fi сети.
                        </Card.Description>

                    </Card.Content>
                </Card>

                <Card style={{ background: 'black' }}>
                    <Card.Content>
                        <Image src={Image13} />

                        <Card.Description style={{ color: 'white' }}>В весах предусмотрена возможность отвязки весов от личного кабинета и перепривязки их в другой личный кабинет(если пчеловод решит перепродать весы другому пчеловоду).</Card.Description>

                    </Card.Content>
                </Card>
                <Card style={{ background: 'black' }} >
                    <Card.Content>
                        <Card.Description style={{ color: 'white' }}>Теперь пройдемся по другому меню, отвечающему за показ графиков.
                            Если нажать в левом углу на выпадающее меню (три черточки), то мы увидим там пункты: На главную, О весах и График.
                        </Card.Description>
                        <Image src={Image14} />

                        <Card.Description style={{ color: 'white' }}>Выбрав пункт график, нам откроется страница с графиком привеса веса в кг, за сегодняшний день</Card.Description>

                    </Card.Content>
                </Card>
                <Card style={{ background: 'black' }} >
                    <Card.Content>
                        <Image src={Image15} />

                        <Card.Description style={{ color: 'white' }}>Внизу по оси Х мы видим время или даты, а по оси Y привесы за это время.
                        </Card.Description>

                    </Card.Content>
                </Card>
                <Card style={{ background: 'black' }} >
                    <Card.Content>
                        <Image src={Image16} />

                        <Card.Description style={{ color: 'white' }}>Так же мы видим, что доступны еще настройки, нажав иконку в виде шестеренки, мы попадем в меню, в котором можем редактировать информацию, выводимую в выпадающем окне, при нажатии в любую точку графика..</Card.Description>

                    </Card.Content>
                </Card>
                <Card style={{ background: 'black' }} >
                    <Card.Content>
                        <Image src={Image17} />

                        <Card.Description style={{ color: 'white' }}>График можно увеличивать или уменьшать. Увеличение позволяет увидеть все точки, а если точнее, то количество принятых данных в личном кабинете пользователя.</Card.Description>

                    </Card.Content>
                </Card>
                <Card style={{ background: 'black' }} >
                    <Card.Content>
                        <Image src={Image18} />

                        <Card.Description style={{ color: 'white' }}>Все уведомления, которые отправляют весы непосредственно пользователю, также дублируются и на графике в виде красных перевернутых треугольников. Если нажать на любую точку, например, на треугольник, мы увидим все данные, которые у нас указаны в настройках для показа, а также внизу само уведомление, позволяющее понять, что происходило с весами. Более подробная информация по графикам, настройке и работе весов смотрите в наших видео.</Card.Description>

                    </Card.Content>
                </Card>
            </Card.Group>
            <Segment inverted></Segment>
        </Container>

    );
}

export default AboutSite;