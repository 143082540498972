import axios from 'axios';
import { AuthResponse } from '../models/response/AuthResponse';





const $api = axios.create({
    withCredentials: true,
    baseURL:"/api/v1",
    headers: {
        'Content-Type': 'application/json',
        'Accept-Encoding': 'br'
    },
});

$api.interceptors.request.use((config) => {
    
    config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
    return config;
});

$api.interceptors.response.use((config) => {
    return config;
}, (async error => {
    const originalRequest = error.config;
    if (error.response.status === 401) {
        try {
            const response = await axios.get<AuthResponse>(`/api/auth/refresh-token`, { withCredentials: true });
            localStorage.setItem('token', response.data.accessToken);
            return $api.request(originalRequest);
        } catch (e) {
            console.log('Не авторизован.');
        }
    }
    return Promise.reject(error);
}))

export default $api;